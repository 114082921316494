export const getTextFromRichtext = (obj, seperator = ' ') => {
  const result = []
  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      const temp = getTextFromRichtext(obj[i])
      if (Array.isArray(temp)) {
        result.push(...temp)
      } else {
        result.push(temp)
      }
    }
  }
  if (typeof obj === 'object') {
    if (obj.text) { return obj.text }
    if (obj.content) { return getTextFromRichtext(obj.content) }
  }
  return result.map(str => str.trim()).join(seperator)
}

export default {
  getTextFromRichtext
}
