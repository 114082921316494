//
//
//
//
//
//
//
//
//
//
//


export default {
  props: {
    text: {
      type: [Object, String],
      default () {
        return {}
      }
    }
  }
}
