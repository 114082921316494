//
//
//
//
//
//
//

import { getTextFromRichtext } from '~/assets/js/utils'
export default {
  props: {
    blok: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    primaryIntroduction () {
      return this.blok.primaryIntroduction || {}
    },
    secondaryIntroduction () {
      return this.blok.secondaryIntroduction || {}
    }
  },
  methods: {
    hasText (rt) {
      if (!rt) { return false }
      return !!getTextFromRichtext(rt)
    }
  }
}
